@import "tailwindcss";

@plugin "daisyui" {
  logs: false;
  themes: light --default;
  themeroot: "[data-theme=jamloop-light]";
}

@plugin "daisyui/theme" {
  name: light;
  default: true;
}

$palette-colors: (
  primary,
  secondary,
  accent,
  info,
  success,
  warning,
  error,
  neutral
);

@mixin generate-tailwind-palette($color) {
  --color-#{$color}: var(--#{$color});
  --color-#{$color}-light: var(--#{$color}-light);
  --color-#{$color}-dark: var(--#{$color}-dark);
  --color-#{$color}-content: var(--#{$color}-content);
}

@theme {
  --font-sans: var(--font-sans);

  --breakpoint-sm: 640px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1280px;
  --breakpoint-2xl: 1536px;

  --color-text-primary: var(--color-text-primary);
  --color-text-secondary: var(--color-text-secondary);

  @each $color in $palette-colors {
    @include generate-tailwind-palette($color);
  }
}

/*
  The default border color has changed to `currentColor` in Tailwind CSS v4,
  so we've added these compatibility styles to make sure everything still
  looks the same as it did with Tailwind CSS v3.

  If we ever want to remove these styles, we need to add an explicit border
  color utility to any element that depends on these defaults.
*/
@layer base {
  *,
  ::after,
  ::before,
  ::backdrop,
  ::file-selector-button {
    border-color: var(--color-gray-200, currentColor);
  }
}

// Styles to hide arrows on number input
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/*
  These classes are added for backwards compatibility with existing DaisyUI buttons.
  They are not applied to DesignSystem buttons.
*/
.btn:not([data-version="2"]) {
  @apply font-normal;
}

.btn-primary:not([disabled]):not([data-version="2"]) {
  @apply text-white;
}

.btn-outline:not([disabled]):not([data-version="2"]) {
  @apply border-primary;
  color: var(--color-primary);
}

.btn-outline:not([disabled]):not([data-version="2"]):hover {
  @apply text-white;
  background-color: var(--color-primary);
}

.btn-error:not([disabled]):not([data-version="2"]) {
  @apply bg-red-800 text-white border-red-800 hover:bg-red-700 hover:border-red-700;
}
